@font-face {
  font-family: "Monument";
  src: url("/assets/fonts/monument/ABCMonumentGrotesk-Regular.woff2") format("woff2"),
       url("/assets/fonts/monument/ABCMonumentGrotesk-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
/* Font not Licensed
@font-face {
  font-family: "Monument";
  src: url("/assets/fonts/monument/ABCMonumentGrotesk-RegularItalic.woff2") format("woff2"),
       url("/assets/fonts/monument/ABCMonumentGrotesk-RegularItalic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}
*/

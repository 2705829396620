$breakpoint-mobile: 770px;
$breakpoint-large: 1400px;

:root {
  font-size: 100%; // 1rem = 16px / user’s custom setting
  line-height: 1.3;
  font-family: 'Monument', 'Helvetica Neue', sans-serif;

  // font sizes
  // --normal-size: 1.5625rem; (25px)
  // --small-size: 1.125rem; (18px)
  // --big-size: 2.5rem; (40px)

  // does’t work in safari – why / why not?
  /*--small-size: min(max(.875rem, 1vw), 1.125rem);
  --normal-size: min(max(1.25px, 1.5vw), 1.5625rem);
  --big-size: min(max(1.75rem, 2.25vw), 2.5rem);*/

  // font-sizes for real browsers
  //@supports (font-size: clamp(1.75rem, 3vw, 2.1rem)) { // safari makes me sick
    --small-size: clamp(.875rem, 1vw, 1.125rem);
    --normal-size: clamp(1.25px, 1.5vw, 1.5625rem);
    --big-size: clamp(1.75rem, 2vw, 2.5rem);
  //}

  // fixed height spaces
  // mainly used on mobile, e.g. description
  --top-bar: 3.75rem;
  --bottom-bar: 4rem;

  // white-space
  --space-top: 1.5rem;
  --space-side: 1.875rem;
  --space-bottom: 1.5rem;

  // horizontal gap in main grid, desktop only
  --main-grid-gap: 3vw;

  // colors
  --pink:	#FBD4D3;
  --orange:	#FECF93;
  --yellow:	#F6FB96;
  --gree:	#D3FBDE;
  --turquoise:#B7ECE8;
  --beige:#E2E7DD;
  --greenblue:#DCEAEB;
  --blue:	#D4E0FB;
  --violet:	#E7BDFF;
  --plan-grey: #F7F7F7;
  --red: #EF9393;
}

@media (min-width: $breakpoint-large) {
  :root {
    font-size: 120%; } // margins
    --space-top: 1rem;
    --space-side: 0.875rem;
    --space-bottom: 1rem;
    // grid
    --main-grid-gap: 5vw;
}

@media (max-width: $breakpoint-mobile) {
  :root {
    // font-sizes
    --small-size: 0.875rem;
    --normal-size: 1.125rem;
    --big-size: 1.75rem;
    // white-space
    --space-top: 0.875rem;
    --space-side: 0.625rem;
    --space-bottom: 1.565rem;
    --main-grid-gap: 0;
  }
}

*, html { box-sizing: border-box; }

body { font-size: var(--normal-size); }

/* Error 404
 * =========
 * this hasn’t been tested
 * turn off dev mode to see
 * But it won’t work, with `npm build` neither.
 */
.error-404 {
  width: 100%;
  height: 100vh;
  display: flex;
  h1 {
    display: inline-block;
    margin: auto;
    font-size: 10vw;
  }
}

/* Layout */

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .main-heading {
    z-index: 11;
    margin-top: var(--space-top);
    margin-left: var(--space-side);
    @media (max-width: $breakpoint-mobile) {
      padding-right: 5rem; // make sure line breaks
      max-width: 20rem;
    }
  }
}

.unstyled-list { // Projects, Images etc.
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Navigation */

nav {
  position: fixed;
  top: 0;
  left: 50%;
  width: 50%;
  z-index: 20;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--top-bar) 1fr (var--bottom-bar);
  grid-gap: var(--main-grid-gap);

  align-items: center;
  //justify-items: center;
  padding-left: var(--space-side);
  section { padding-left: 1em; }

  @media(min-width: $breakpoint-mobile) { section { padding-left: 5vw; } }


  .hamburger { width: 2.375rem; }
  .close {
    width: 1.625rem;
    height: 1.625rem;
    margin-bottom: 3rem;
  }
  @media (max-width: $breakpoint-mobile) { // mobile nav
    left: 0;
    width: 100%;
    overflow-y: scroll;
    .hamburger,
    .close {
      position: relative;
      top: 5px; // align with headings
    }
  }

  // nav-state (active/inactive)
  &.active {
    height: 100%; // just for you, safari
    background: var(--turquoise);
    bottom: 0;
    .hamburger { display: none; }
    .nav-content {
      display: block;
    }
  }
  &.inactive {
    background: transparent;
    .close { display: none; }
    section.nav-content { display: none; }
  }

  .nav-ui,
  .sub-nav-ui { // the latter is the close button in the ProjectLinkList
    display: inline-block;
    position: fixed;
    top: var(--space-top);
    right:  var(--space-side);
  }

  .nav-content {
    &:first-of-type { margin-top: auto; }
    &:last-of-type { margin-bottom: auto; }
    ul {
      margin-bottom: 5rem;
      li {
        font-size: var(--big-size);
      }
    }
  }

  .project-list { // Werkliste
    position: fixed;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 50%;
    background: var(--orange);
    padding-top: 5rem;
    padding-right: var(--space-side);
    padding-left: var(--space-side);
    z-index: 200;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    overflow-y: scroll;
    > ul { // list of project types
      li { // contains lists of projects
        .category-title { margin-bottom: 0.25rem; }
        ul {
          margin-bottom: 3rem;
          li {
            font-size: var(--big-size);
            @media(min-width: $breakpoint-mobile) and (max-width: 1650px) { font-size: clamp(1.5rem, 1.75vw, 2.5rem); }
          }
        }
      }
    }
    @media (max-width: $breakpoint-mobile) {
      left: 0;
      width: 100%;
      // height: 80%;
      padding: var(--top-bar) var(--space-side) 0;
      margin-bottom: 0;
      overflow-y: scroll;
      // -webkit-overflow-scrolling: touch;
      /*
      // scrollbar-width: none; // firefox
      // &::-webkit-scrollbar { // safari & chrome
        // this is not good yet. scroll seems to be disabled in some cases!
        display: none;
      // }
      */
    }
  }

  // nav mobile
  @media (max-width: 770px) {
    left: 0;
    width: 100%;
  }
}

/* Fullpage.js */

div.section {
  position: relative;
  // allows absolute positioning inside viewport
  @media (max-width: $breakpoint-mobile) {
    h1 {
      padding-top: var(--space-top);
      padding-left: var(--space-side);
      padding-right: var(--space-side);
      // all section titles on mobile are placed like
      // the site title ( which is fixed)
      // thus, extra padding/margin is needed on top
      // of every section content
      position: absolute;
      // what is not so good, ist that all h1 tags
      // further down, e.g. team member titles
      // need to be reset (See next rule just below),
      // usually they should be inside a section tag.
    }
    section,
    article {
      h1 {
        position: static;
        padding: 0;
      }
    }
  }
}

/* Projects
 * ========

  Structure:

  h1.project-title
  section.project-description
    div.desription-content
    p.description-toggle
  section.project-images
    ul.unstyled-list
    p.image-counter
  section.project-plans
    ul.unstyled-list
    p.image-counter
  section.optional-background
*/

.project { // article, project content
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // unfortunate: padding/margin at bottom won’t work
  // quick & dirty solution: empty grid row :-(
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: var(--top-bar) 1fr var(--bottom-bar); // title, content, ui/counters
  grid-gap: var(--main-grid-gap) 0;
  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
  }
  .project-title {
    z-index: 10;
    margin: var(--space-top) var(--space-side) 0;
    grid-column: 2/-1;
    grid-row: 1/2;
    padding-right: 4rem;
  }
  .project-left-col {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .project-right-col {
    grid-column: 2/-1;
    grid-row: 2/3;
  }
  .project-plans,
  .project-images {
    @media (max-width: $breakpoint-mobile) {
      // gets disabled in vue,
      // but better safe than sorry.
      display: none;
    }
  }
  .project-plans,
  .project-images,
  .project-images-plans-combo {
    z-index: 3;
    overflow: hidden;
    justify-self: stretch;
    grid-row: 2/-1; // stretch to bottom;
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr var(--bottom-bar);
    grid-gap: var(--main-grid-gap) 0;
    padding: 0 var(--space-side);
    ul {
      margin: 0;
      justify-self: stretch;
      align-self: stretch;
      grid-column: 1/-1;
      grid-row: 1/2;
      // border: 1px solid tomato;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      li, figure {
        display: contents; // best CSS property, ever!
      }
      img {
        max-width: 100%;
        max-height: 100%;
        &.plan {
          // padding: 1rem; // preferably not neccassary
          background: var(--plan-grey);
        }
        @media(min-width: $breakpoint-mobile) {
          max-height: calc((50vw - (var(--space-side) * 2)) * 0.75);
        }
      }
    }
    .image-counter {
      display: inline-block;
      // align-self: end;
      @media (max-width: $breakpoint-mobile) {
        position: absolute;
        bottom: 0;
        margin: 0;
        right: var(--space-side);
        padding: 1.5rem 0 var(--space-bottom) 0;
        height: var(--bottom-bar);
      }
    }
  }
  .image-counter {
    align-self: end; // vertical alignment
    padding-bottom: var(--space-bottom);
  }
  .project-left-col .image-counter {
    justify-self: start; // horizontal alignment
    grid-column: 1/2;
  }
  .project-right-col .image-counter {
    justify-self: end; // horizontal alignment
  }
  .project-images-plans-combo .image-counter { justify-self: end; }

  .project-images-plans-combo {
    // only visible on small viewports
    // gets handled in …
    //   - component/Project
    //   - component/Projectstudy
    //   - component/Competition
    // … by way of store.js
    z-index: 3;
    grid-row: 2/-1;
    grid-column: 1/2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr var(--bottom-bar);
    ul { margin-top: 1rem; } // a rather cumbersome way of getting white space at the top.
  }
  .project-date-and-type {
    //
  }
  .project-type, .project-date, .project-prize-not-competition, .project-prize-not-competition p { margin-bottom: 0; }
  .project-description {
    padding: 0 var(--space-side) var(--space-bottom);
    z-index: 4;
    grid-row: 2/-1;
    // align-self: stretch;
    // justify-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr calc(var(--bottom-bar) - var(--space-bottom));
    grid-gap: var(--main-grid-gap) 0;
    .projectinfos {
      margin-bottom: 1.5rem;
    }
    .description-toggle {
      margin: 0;
      width: 100%;
      grid-row: 2/3;
      text-transform: uppercase;
    }
    &.inactive {
      align-self: end; // no click conflicts
      align-content: end; // content cut off, but bottom content visible
      height: var(--bottom-bar);
    }
    &.active {
      align-self: stretch;
      z-index: 100;
      .description-content {
        overflow-y: scroll;
        justify-self: center;
        scrollbar-width: none; // firefox
        &::-webkit-scrollbar { // safari & chrome
          display: none;
          // all this scrollbar stuff seemed ok at first, but I fear it breaks scrolling in many cases
        }
        .description-body {
          padding: 0 4vw;
          @media (max-width: $breakpoint-mobile) {
            padding: 0;
            .type-and-prizes-container-above { //
              display: block;
              margin-bottom: .75rem;
              p { margin-bottom: 0; }
            }
          }
          @media (min-width: $breakpoint-large) { padding: 0 5vw; }
        }
      }
      .type-and-prizes-container-below { // competitons & projectstudies
        grid-row: 2/-1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .project-type-below,
        .project-prizes-below { margin-bottom: 0; }
        .project-type-below {
          grid-column: 1/2;
        }
        .project-prizes-below {
          grid-column: 2/-1;
          justify-self: end;
          p { // Should be possible to be more than one,
              // but this would lead to trouble.
            margin-bottom: 0;
            text-align: right;
          }
        }

        @media (max-width: $breakpoint-mobile) {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%); // hack … is this ok?
          bottom: 4rem;
        }
      }
      .description-toggle { // active
      }
      @media (max-width: $breakpoint-mobile) {
        overflow: hidden;
        .description-content {
          max-width: 100%;
          padding: 1.5rem var(--space-side) 1rem;
          overflow-y: scroll;
          position: absolute;
          top: var(--top-bar);
          bottom: var(--bottom-bar);
          left: 0;
          transform: none;
        }
        .description-toggle {
          // active
          // border-top: solid 1px rgba(0, 0, 0, .4); // tbd
        }
      }
    }
    .description-toggle { // general styles
      display: inline-block;
      align-self: end;
      @media (max-width: $breakpoint-mobile) {
        position: absolute;
        bottom: 0;
        margin: 0;
        padding: 1.5rem 0 var(--space-bottom) var(--space-side);
        height: var(--bottom-bar); // corresponds with project-grid
        // border-top: solid 1px rgba(0, 0, 0, 0); // just too keep the height right
        grid-row: 3/4;
      }
    }
  }
  .optional-background {
    z-index: 0;
    // transition: opacity .1s linear;
    &.transparent { opacity: 0; }
    &.filled {
      grid-column: 2/-1;
      grid-row: 1/-1;
      opacity: 1;
    }
  }
  @media (max-width: $breakpoint-mobile) {
    .project-title {
      // could get positioned absolutely
      // are reset, see above
      // but can stay like this, sees to work well
      grid-column: 1/-1;
    }
    .project-left-col,
    .project-right-col,
    .project-description,
    .project-images-plans-combo { grid-column: 1/-1; }

    .optional-background.filled {
      grid-column: 1/-1;
      grid-row: 1/-1;
    }
  }
} // end article.project
.competition,
.projectstudy {
  .project-images .image-counter {
    justify-self: start;
  }
  //keeps background for projektstudie and competitions in desktop.
  .optional-background.transparent {
    grid-column: 2/-1;
    grid-row: 1/-1;
    opacity: 1;
  }
}

/* Generic two col grid page
 * (i.e. Buero and Contact)
 * ========================
 */

.generic-two-col-grid {
  // layout same as in .project
  // classes could/should be merged!?
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100vh;
  // overflow: hidden; // wtf?
  display: grid; // same layout as .project article
  grid-template-columns: 1fr 1fr;
  grid-template-rows: var(--top-bar) 1fr var(--bottom-bar);
  grid-gap: var(--main-grid-gap) 0;
  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
  }
  .generic-two-col-grid-section {
    grid-row: 2/-1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr var(--bottom-bar);
    grid-gap: var(--main-grid-gap) 0;
  }
  .two-col-grid-left { grid-column: 1/2; }
  .two-col-grid-right {
    grid-column: 2/-1;
    h1 { // align with website title
      position: absolute;
      top: var(--space-top);
      padding-left: var(--space-side);
      @media (max-width: $breakpoint-mobile) {
        padding-left: 0;
      }
    }
    .right-hand-content {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media (max-width: $breakpoint-mobile) {
  grid-template-columns: 1fr;
    .two-col-grid-left,
    .two-col-grid-right {
      grid-column: 1/-1;
    }
    .two-col-grid-right .right-hand-content { width: 100%; }
  }
}

/* Büro Page
 * (Team & Unternehmen)
 * ====================

  Structure:

  div.team-and-about-container
    section.team-section
      ul
        li
          div.pair-container // only desktop
            article.team-member
              h1
              p.role
              p.jobtitle
              p.phone
              p.mail
              figure.team-foto
                img
                pcaption
      p.team-pairs-counter
    .about-section
      article
       h1
       div.about-text
*/

.team-and-about-container {
  z-index: 1; // in front of background-section
  .team-section {
    margin: 0 var(--space-side) var(--space-bottom);
    align-items: center; // Susanne’s orders
    line-height: 1.2;
    .team-pairs-counter { align-self: end; }
    .pair-container { // only on desktop
      padding: 0 2vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr;
      grid-gap: 0 3vw; // arbitrary gap, no real spec
      .team-member {
        // display: flex;
        // flex-direction: column;
        display: grid;
        grid-template-rows: min-content;
        grid-row-gap: 0.5rem;
        grid-template-columns: 1fr;
        font-size: var(--small-size);
        .name { margin-top: auto; }
        .email { word-break: break-all; } // 😭 painful shit
        .foto {
          img { max-width: 100%; object-fit: cover; }
        }
      }
    }
    @media (max-width: $breakpoint-mobile) {
      align-items: center;
      width: 100%;
      justify-self: center;
      .team-member {
        font-size: var(--small-size);
        margin: 0 auto;
        max-width: 75%;
        display: flex;
        flex-direction: column;
        .foto {
          margin-bottom: .5rem;
          order: -1;
          img { width: 100%; }
        }
      }
      .team-single-counter {
        grid-row: -2/-1;
        align-self: end;
        text-align: right;
        padding: 0 var(--space-side);
      }
    }
  }
  .about-section {
    .buero-sections-background {
    }
    z-index: 1;
    padding: 0 var(--space-side);
    @media(max-width: $breakpoint-mobile) {
      .text-container {
        z-index: 1;
        .about-text {
          width: 100%;
          overflow-y: scroll;
          position: absolute;
          top: var(--top-bar);
          bottom: var(--bottom-bar);
          left: 0;
          padding: var(--space-side);
          transform: none;
        }
      }
    }
  }
  @media (max-width: $breakpoint-mobile) { // .team-and-about-container
    height: auto; // the effing reason for all my sorrow
  }
}

// «Team», only visible on mobile
.mobile-team-title {
  position: absolute;
  top: 0;
}

/* Kontakt Page
 * ============

 Structure:

  div.map-and-contact-container
    section.map-section
    section.contact-section
      article
        h1
        div.contact-content
          p.address
          p.phone-fax-mail
*/

.map-and-contact-container {
  z-index: 1; // in front of background-section
  .map-section {
    margin: 0 var(--space-side) var(--space-bottom);
    // justify-content: space-between;
    .map {
      height: 100%;
      max-height: 65vh;
    }
  }
  .contact-section {  }
}
@media(max-width: $breakpoint-mobile) {
  .map-and-contact-container {
    padding: 0 var(--space-side);
    grid-template-rows: var(--top-bar) auto 1fr;
    grid-gap: 1rem;
    .map-section {
      margin: 0;
      padding: 0;
      grid-row: 3/-1;
      max-height: auto; // otherwise collides with contact info
      .map { max-height: auto; }
    }
    .contact-section {
      grid-row: 2/3;
      display: block;
    }
  }
}

// Backgrouds behind buero-sections

.buero-sections-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 50%;
  @media(max-width: $breakpoint-mobile) {
    left: 0;
    width: 100%;
  }
  &.about-section-background { background: var(--yellow); }
  &.contact-section-background { background: var(--violet); }
}

/* UI-Elements, Buttons
 * ====================
 */

.pointer { cursor: pointer; }

/* Links
 * =====
 */

a { color: black; }
a:link { }
a:visited { }
a:focus { }
a:hover { }

a.un-deco { // helper class
  text-decoration: none;
  &:hover { text-decoration: underline; }
}

/* Text Containers
 * ===============
 *
 * e.g. Redactor field content
 */

.text-container {
  h1, h2, h3, h4, h5, h6,
  ul, p { margin-bottom: .75rem;}

  strong, b { }
  em { }
  ul {
    padding-left: .7rem;
    li {
      padding-left: .25rem;
      &::marker { content: "–"; }
      // there are no lists in the design
      // so might be better to disable this.
    }
  }
}

/* Helper Classes */
.upper { text-transform: uppercase; }

/* user info, only while under construction */
.user-info {
  position: fixed;
  bottom: .75rem;
  left: calc(var(--space-side) + 1px);
  border-radius: 3px;
  padding: 0 .25rem .05rem;
  line-height: 1.4;
  background: var(--greenblue);
  color: #222;
  // border: solid 1px pink;
  font-size: 0.75rem;
  z-index: 10000;
  box-shadow: 1px 1px 2px #c6c6c6;
  @media (max-width: $breakpoint-mobile) {
    display: none;
  }
}

/* under construction */
.under-construction {
  position: fixed;
  max-height: 100vh;
  min-height: 600px;
  // overflow: hidden;
  .two-col-grid-left {
    z-index: 1;
    margin: 0 var(--space-side) var(--space-bottom);
    justify-content: center;
    figure {
      max-width: 75%;
      height: 90%;
      align-self: center;
      justify-self: center;
      img { max-width: 100%; }
    }
    .teaser-text {
      //margin-top: auto;
      position: absolute;
      bottom: var(--space-bottom);
      margin-left: var(--space-side);
    }
  }
  .contact-section {
    z-index: 1;
    .text-container {
      margin-top: 4rem;
    }
  }
  .left-side-background { // it’s the right side, of course
    background: var(--violet);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    z-index: 0;
    width: 50%;
  }
}
@media(max-width: $breakpoint-mobile) {
  // this is some frantic christmas-eve-coding
  // no design specs for mobile afaik
  // looks rather horrible, but I have to go decorate the 🎄
  .under-construction-header { position: absolute; }
  .under-construction {
    position: relative;
    width: 100vw;
    height: auto;
    max-height: unset;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2rem auto auto;
    grid-gap: 2.5rem;
    padding-bottom: var(--bottom-bar);
    .contact-section {
      display: block;
      margin-top: 1rem 0;
      grid-row: 3/4;
      padding: 0 var(--space-side) 0 !important;
      .text-container {
        margin: 0;
        padding: 0;
        p:last-child { margin: 0; }
      }
      .address-title,
      h1 { display: none; }
    }
    .two-col-grid-left {
      width: 100%;
      margin: 0 !important;
      padding: 0 var(--space-side) !important;
      grid-row: 2/3;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      .teaser-text, figure { max-width: 75%; }
      .teaser-text {
        position: static;
        display: inline-block;
        margin: 0 0 1rem;
        padding: 0 2rem;
        text-align: center;
      }
      figure {
        align-self: center;
      }
      order: 1;
      padding: 0;
      height: 100%;
      margin-top: 2rem; // safari is stupid

    }
    .contact-section {
      order: 0;
      padding-bottom: 2rem;
    }
    .left-side-background {
      left: 0;
      bottom: 0;
      width: 100vw;
    }
  }
}

/*impressum*/
.impressum-link { cursor: pointer; }
.impressum {
  background: var(--red) !important;
  display: flex;
  .impressum-body {
    padding: 0 5vw 3em 5vw;
    margin-top: auto;
    margin-bottom: auto;
    p {
      margin-bottom: 1em;
      max-width: 19em;
    }
    a { text-decoration: none; }
    a:hover { text-decoration: underline; }
  }
  .impressum-title {
    z-index: 10;
    margin: var(--space-top) var(--space-side) 0;
    position: absolute;
    top: 0;
    text-transform: uppercase;
    left: 0;
  }
  @media(max-width: $breakpoint-mobile) {
    .impressum-body { padding-left: 0 }
  }
}
